import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosPatch, axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildItemSubType, buildItemType } from "./helpers";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  itemTypeList: [],
  itemSubTypeList: [],
  filteredItemTypeList: [],
  filteredItemSubTypeList: [],
  updateStatus: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isUpdateLoading = false;
  state.error = error;
};

const itemTypeSlice = createSlice({
  name: "itemTypes",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getItemTypesSuccess(state, action) {
      const { itemTypes } = action.payload;
      state.itemTypeList = itemTypes;
      state.isLoading = false;
      state.error = null;
    },
    getItemSubTypesSuccess(state, action) {
      const { itemSubTypes } = action.payload;
      state.itemSubTypeList = itemSubTypes;
      state.isLoading = false;
      state.error = null;
    },
    getFilteredItemTypeSuccess(state, action) {
      const { itemTypes } = action.payload;
      state.filteredItemTypeList = itemTypes;
      state.isLoading = false;
      state.error = null;
    },
    getFilteredItemSubTypeSuccess(state, action) {
      const { itemSubTypes } = action.payload;
      state.filteredItemSubTypeList = itemSubTypes;
      state.isLoading = false;
      state.error = null;
    },
    createItemTypeSuccess(state, action) {
      const { itemType } = action.payload;
      let newItemTypes = state.filteredItemTypeList.concat(itemType);
      state.filteredItemTypeList = newItemTypes;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    createItemSubTypeSuccess(state, action) {
      const { itemSubType } = action.payload;
      let newItemSubTypes = state.filteredItemSubTypeList.concat(itemSubType);
      state.filteredItemSubTypeList = newItemSubTypes;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    updateItemTypeSuccess(state, action) {
      const { itemType } = action.payload;
      let updatedItemTypes = state.filteredItemTypeList.map((type) => {
        if (type.id === itemType.id) {
          return itemType;
        } else return { ...type };
      });
      state.filteredItemTypeList = updatedItemTypes;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    updateItemSubTypeSuccess(state, action) {
      const { itemSubType } = action.payload;
      let updatedItemSubTypes = state.filteredItemSubTypeList.map((subType) => {
        if (subType.id === itemSubType.id) {
          return itemSubType;
        } else return { ...subType };
      });
      state.filteredItemSubTypeList = updatedItemSubTypes;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    setUpdateSuccess(state, action) {
      const { updateStatus } = action.payload;
      state.updateStatus = updateStatus;
    },
    clearItemTypes(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.itemTypeList = [];
      state.itemSubTypeList = [];
      state.filteredItemTypeList = [];
      state.filteredItemSubTypeList = [];
      state.updateStatus = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  getItemTypesSuccess,
  getItemSubTypesSuccess,
  getFilteredItemTypeSuccess,
  getFilteredItemSubTypeSuccess,
  createItemTypeSuccess,
  createItemSubTypeSuccess,
  updateItemTypeSuccess,
  updateItemSubTypeSuccess,
  setUpdateSuccess,
  clearItemTypes,
  setFailure,
} = itemTypeSlice.actions;

const mapItemSubTypes = (types) =>
  types.map((type) => ({
    id: type.id,
    complexityScore: type["complexity-score"]?.toLowerCase?.(),
    estimatedCost:
      type["estimated-cost"] && Number(type["estimated-cost"]).toFixed(2),
    label: type.label || "Default",
    qtyPerPack: type["qty-per-pack"],
    leadTimeInDays: type["lead-time-in-days"],
    moq: type.moq,
  }));

const mapItemTypes = (types) => {
  let mappedItemTypes = types.map((type) => ({
    id: type.id,
    categoryCode: type["category-code"],
    name: type.description,
    packSize: type["qty-per-pack"],
    leadTime: type["lead-time-in-days"],
    externalId: type["external-id"],
    qtyPerPack: type["qty-per-pack"],
    sendToContentHub: type["send-to-content-hub"],
    rtaDeployment: type["rta-deployment"],
    isActive: type["is-active"],
    specType: type["spec-type"],
    posType: type["pos-type"],
    itemSubTypes: mapItemSubTypes(type["item-sub-types"]),
  }));

  return mappedItemTypes;
};

export default itemTypeSlice.reducer;

export const fetchAllItemTypes = (filters) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/item-types`);
    if (response.error) throw response.error;
    const mappedData = mapItemTypes(response.data);
    dispatch(getItemTypesSuccess({ itemTypes: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Types" }));
  }
};

export const fetchAllItemSubTypes = (filters) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/item-sub-types`);
    if (response.error) throw response.error;
    const mappedData = mapItemSubTypes(response.data);
    dispatch(getItemSubTypesSuccess({ itemSubTypes: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Types" }));
  }
};

export const fetchFilteredItemTypes = (desc) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const queryString =
      desc && desc.length > 0
        ? `/api/item-types?filter[description]=${desc}`
        : "/api/item-types";
    const response = await axiosGet(queryString);
    if (response.error) throw response.error;
    const mappedData = mapItemTypes(response.data).sort((a, b) => {
      return +a.externalId < +b.externalId
        ? -1
        : +a.externalId > +b.externalId
        ? 1
        : 0;
    });
    dispatch(getFilteredItemTypeSuccess({ itemTypes: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Types" }));
  }
};

export const fetchFilteredItemSubTypes = (label) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const queryString =
      label && label.length > 0
        ? `/api/item-sub-types?filter[label]=${label}`
        : "/api/item-sub-types";
    const response = await axiosGet(queryString);
    if (response.error) throw response.error;
    const mappedData = mapItemSubTypes(response.data).sort((a, b) => {
      return +a.externalId < +b.externalId
        ? -1
        : +a.externalId > +b.externalId
        ? 1
        : 0;
    });
    dispatch(getFilteredItemSubTypeSuccess({ itemSubTypes: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Types" }));
  }
};

export const addNewItemType = (itemType) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildItemType(itemType);
    const response = await axiosPost("/api/item-types", postData);
    if (response.error) throw response.error;
    const mappedData = mapItemTypes([response.data])[0];
    dispatch(createItemTypeSuccess({ itemType: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Types" }));
  }
};

export const updateItemTypeById = (itemType) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildItemType(itemType);
    let url = `/api/item-types/${itemType.id}`;
    const response = await axiosPatch(url, patchData);
    if (response.error) throw response.error;
    const mappedData = mapItemTypes([response.data])[0];
    dispatch(updateItemTypeSuccess({ itemType: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Types" }));
  }
};

export const updateItemSubTypeById = (itemSubType) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildItemSubType(itemSubType);
    let url = `/api/item-sub-types/${itemSubType.id}`;
    const response = await axiosPatch(url, patchData);
    if (response.error) throw response.error;
    const mappedData = mapItemSubTypes([response.data])[0];
    dispatch(updateItemSubTypeSuccess({ itemSubType: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Types" }));
  }
};
