import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    thunk: true,
    immutableCheck: false,
    serializableCheck: false,
  });

export const store = configureStore({
  reducer: rootReducer,
  middleware,
});
