/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { Dialog, DialogContent, Typography } from "@mui/material";

import { utcDate } from "src/utility/utilityFunctions";

import { CreateProgramForm } from "@features/programs";

import { createProgram } from "../../../redux/slices/planningTool/programSlice";

const ProgramActivateModal = ({ handleClose, anaplanProgram }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    programTypeId: anaplanProgram.programTypeId,
    channel: anaplanProgram.channel,
    orderType: "on-demand",
    name: anaplanProgram.name,
    startDate: utcDate(anaplanProgram.startDate),
    endDate: utcDate(anaplanProgram.endDate),
  };

  const handleActivate = (data) => {
    setLoading(true);
    dispatch(
      createProgram(
        {
          ...data,
          anaplanProgramId: anaplanProgram.id,
        },
        handleClose
      )
    );
  };

  const onClose = () => {
    if (loading) return;
    handleClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Typography style={{ textAlign: "center" }}>
          Activate Program
        </Typography>
        <CreateProgramForm
          defaultValues={defaultValues}
          onSubmit={handleActivate}
          submitButtonText="Activate"
          disabledFields={["programTypeId", "channel", "name"]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ProgramActivateModal;
