import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isFuture, isThisMonth } from "date-fns";
import { format } from "date-fns/esm";

import { axiosGet } from "../api/axiosCalls";
import {
  fetchFutureOcms,
  fetchOpenWindows,
} from "../redux/slices/orderCalendarMonthsSlice";
import { fetchFilteredAnaplanPrograms } from "../redux/slices/planningTool/anaplanProgramSlice";
import { camelCaseKeys, formatDate } from "../utility/utilityFunctions";

export const useOrderCalendarMonthOptions = (ocm) => {
  const dispatch = useDispatch();
  const { future: futureOcms, singleOcm } = useSelector(
    (state) => state.orderCalendarMonths
  );
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (futureOcms.length) {
      // Filter past order window open dates
      let ocms = futureOcms.filter(
        ({ orderWindowCloseDate }) =>
          isFuture(new Date(orderWindowCloseDate)) ||
          isThisMonth(new Date(orderWindowCloseDate))
      );

      if (
        ocm &&
        ocm.inMarketStartDate &&
        !ocms.find(({ id }) => id === ocm.id)
      ) {
        // prepend to array
        ocms.unshift(ocm);
      }

      const ocmOptions = ocms.map((e) => ({
        ...e,
        label: e.orderWindowName,
      }));

      // Add on-demand option
      setOptions([{ id: "on-demand", label: "On Demand Only" }, ...ocmOptions]);
    } else {
      dispatch(fetchFutureOcms());
    }
  }, [futureOcms, ocm, singleOcm, dispatch]);

  return options;
};

export const useFutureInMarketMonths = () => {
  const dispatch = useDispatch();
  const { future: futureOcms } = useSelector(
    (state) => state.orderCalendarMonths
  );
  const [options, setOptions] = useState([]);
  useEffect(() => {
    (async () => {
      if (futureOcms.length) {
        setOptions(
          futureOcms.map((e) => ({
            ...e,
            label: format(formatDate(e.inMarketStartDate), "MMMM yyyy"),
          }))
        );
      } else {
        dispatch(fetchFutureOcms());
      }
    })();
  }, [futureOcms, dispatch]);
  return options;
};

export const useOCName = () => {
  const dispatch = useDispatch();
  const { windows: windowsOcms } = useSelector(
    (state) => state.orderCalendarMonths
  );
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (windowsOcms.length) {
      const uniqueDateOptions = [...windowsOcms]
        .sort(
          (a, b) => b.orderWindowName.slice(-4) - a.orderWindowName.slice(-4)
        ) // order by reverse year but keep months in order
        .map((ow, _) => ({
          id: ow.id,
          name: ow.orderWindowName,
        }));
      setOptions(uniqueDateOptions);
    } else {
      dispatch(fetchOpenWindows());
    }
  }, [windowsOcms, dispatch]);

  return options;
};

export const useItemTypes = (filter) => {
  const { itemTypeList } = useSelector((state) => state.itemTypes);
  return useMemo(
    () =>
      itemTypeList
        .filter((type) => type.isActive && (filter ? filter(type) : true)) // use given filter if provided,
        .map((t) => ({ ...t, label: `${t.externalId} ${t.name}` })),
    [itemTypeList, filter]
  );
};

export const useCouponTypes = () => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let cancelled = false;
    axiosGet("/api/coupon-types").then(
      (res) =>
        !cancelled &&
        setOptions(
          res.data.map((obj) => ({
            ...camelCaseKeys(obj),
            label: `${obj["type-code"]} ${obj["offer-type-code"]}`,
          }))
        )
    );
    return () => (cancelled = true);
  }, []);

  return options;
};

export const useProgramInfo = () => {
  const program = useSelector((state) => state.program.currentProgram);

  const { status, orderType, id } = program;

  return {
    programId: id,
    requiresOCM: orderType === "pre-order",
    isSubmitted: status !== "draft",
    becomesOnDemand: orderType === "on-demand",
    isInventory: orderType === "from-inventory",
    ...program,
  };
};

export const useHandleSubmitErrors = (setError, id) => {
  const errors = useSelector(
    (state) => state.itemPrograms.submitErrors[id] || []
  );
  useEffect(() => {
    errors.forEach(({ name, message }) =>
      setError(name, { type: "custom", message })
    );
  }, [errors, setError, id]);

  return errors.length > 0;
};

export const useMapItemIdToItemProgramId = (itemId) => {
  // Warning, a direct one-to-one relationship between itemProgramId and itemId cannot be guarandeed for re-runs.
  const itemPrograms = useSelector((state) => state.itemPrograms.entities);
  if (!itemId) return;
  return Object.values(itemPrograms).find((ip) => ip.itemId === itemId)?.id;
};

export const useAnaplanPrograms = (filterObject) => {
  const dispatch = useDispatch();
  const { nextLink, isNextLoading, pagesLoaded, isLoading, programs } =
    useSelector((state) => state.anaplanPrograms);

  useEffect(() => {
    dispatch(fetchFilteredAnaplanPrograms(filterObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return { nextLink, isNextLoading, pagesLoaded, isLoading, programs };
};
