import { upCase } from "src/utility/utilityFunctions";

import { createSlice } from "@reduxjs/toolkit";

/*
* Filter Model
notes: filters are determined soley from the client logic and have nothing
to do with calls to the api for their creation.  They are used within api
calls to determine query strings that will be sent to the api.  All arrays
will include objects with an id key, and a name key ({id: string, name: string})
or just a single string represting the name and filter.  Every other field
is a string.
*/

const initialFilters = {
  // Filters
  fromDate: null,
  toDate: null,
  bu: [],
  brand: [],
  address: [],
  groupBy: null,
  itemType: [],
  favItems: [],
  month: [],
  orderType: null,
  itemOrderType: null,
  orderVariantIds: [],
  poNum: null,
  program: [],
  rfpNum: null,
  ruleType: null,
  itemNumber: null,
  itemDesc: null,
  orderNum: null,
  isAccolade: null,
  isItemOrderable: null,
  isItemArchived: null,
  isFastTrack: null,
  isItemDraft: null,
  channel: null,
  isPreOrderActive: null,
  hasShipHold: false,
  status: "",
  supplier: [],
  tag: [],
  stateIds: [],
  territory: [],
  exclusiveTerritory: [],
  currentTerritoryId: null,
  type: null,
  user: [],
  purchaser: [],
  userName: null,
  excludedSupplier: null,
  supplierReference: null,
  c2mTerritory: null,
  c2mProgramName: null,
  c2mBrands: null,
  c2mBusinessUnit: null,
  c2mDistributor: null,
  c2mOrderDateFrom: null,
  c2mOrderDateTo: null,
  c2mInMarketDateFrom: null,
  c2mInMarketDateTo: null,
  c2mName: null,
  c2mProgramTheme: null,
  c2mItemType: null,
  supplierQuoteId: null,
  programName: null,
  workfrontId: null,
  programId: null,
  programType: [],
  orderWindowMonthYear: null,
  inMarketYear: [],
  inMarketMonthYears: [],
  couponBarcodeId: null,
  sortOrder: null,
  sortOrderBy: null,
  sortProgramsBy: null,
  codeOrName: "",
  budgets: [],
  anaplanCode: "",
  poInvoiceNumber: null,
  poExternalSapId: null,
  anaplanProgramType: null,

  // Not filters, though needed for reset and when setting defaults
  chipList: [],
  clearFilters: false,
  clearFreeType: false,
};

// Other state variables
const additionalStateVars = {
  defaultFilters: null,
  fetchCurrent: false,
  sorted: false,
  filterType: null,
  filtersOpen: false,
};

let initialState = {
  ...initialFilters,
  ...additionalStateVars,
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFiltersOpen(state, action) {
      const { open } = action.payload;
      state.filtersOpen = open;
    },
    setFilterType(state, action) {
      const { type } = action.payload;
      state.filterType = type;
    },
    setDefaultFilters(state, action) {
      const { filterObject } = action.payload;
      Object.assign(state, initialFilters); // Modifies state directly, sets all filters to initial state
      state.defaultFilters = { ...filterObject };
    },
    updateMultipleFilters(state, action) {
      const { filterObject } = action.payload;
      for (let filter in filterObject) {
        state[filter] = filterObject[filter];
      }
    },
    updateSingleFilter(state, action) {
      const { filter, value } = action.payload;
      state[filter] = value;
    },
    setClear(state) {
      state.clearFilters = true;
    },
    setSorted(state) {
      state.sorted = !state.sorted;
    },
    setFetchCurrent(state) {
      state.fetchCurrent = !state.fetchCurrent;
    },
    setClearFreeType(state, action) {
      const { value } = action.payload;
      state.clearFreeType = value;
    },
    resetFilters(state) {
      Object.assign(state, initialFilters); // Modifies state directly, sets all filters to initial state
    },
    setChips(state, action) {
      const filterType = action.payload.filterType ?? state.filterType;
      let chippable = [];
      if (filterType.includes("item-")) {
        chippable = [
          "bu",
          "brand",
          "itemType",
          "itemOrderType",
          "favItems",
          "program",
          "itemNumber",
          "itemDesc",
          "exclusiveTerritory",
          "isAccolade",
        ];
      }
      if (
        filterType.includes("history") ||
        filterType === "rfp" ||
        filterType === "po" ||
        filterType === "orders" ||
        filterType === "rollup" ||
        filterType.includes("Supplier")
      ) {
        chippable = [
          "bu",
          "brand",
          "address",
          "itemType",
          "program",
          "itemNumber",
          "user",
          "rfpNum",
          "poNum",
          "orderNum",
          "supplier",
          "purchaser",
          "stateIds",
          "territory",
          "orderType",
          "type",
          "supplierReference",
          "c2mTerritory",
          "c2mProgramName",
          "c2mBrands",
          "c2mBusinessUnit",
          "c2mOrderDateFrom",
          "c2mOrderDateTo",
          "c2mInMarketDateFrom",
          "c2mInMarketDateTo",
          "c2mDistributor",
          "c2mName",
          "c2mProgramTheme",
          "c2mItemType",
          "programName",
          "budgetId",
          "budgets",
          "noBudgets",
          "poInvoiceNumber",
          "poExternalSapId",
          "anaplanCode",
          "anaplanProgramType",
        ];
      }
      if (filterType.includes("program")) {
        chippable = ["month", "brand", "bu"];
      }
      if (filterType.includes("planningTool")) {
        chippable = [
          "bu",
          "brand",
          "channel",
          "itemNumber",
          "programId",
          "programName",
          "workfrontId",
          "couponBarcodeId",
          "programType",
          "inMarketYear",
          "inMarketMonthYears",
          "orderWindowMonthYear",
        ];
      }
      if (filterType.includes("planningToolActivate")) {
        chippable = ["codeOrName"];
      }
      if (filterType.includes("RFQ")) {
        chippable = [
          "bu",
          "brand",
          "itemNumber",
          "itemType",
          "itemDesc",
          "programName",
        ];
      }
      if (filterType.includes("quotes")) {
        chippable = ["supplierQuoteId", "itemNumber", "brand", "itemType"];
      }
      if (filterType.includes("itemRollup")) {
        chippable = [
          "brand",
          "program",
          "itemType",
          "itemNumber",
          "bu",
          "stateIds",
          "supplier",
        ];
      }
      if (filterType.includes("compliance")) {
        chippable = [
          "brand",
          "program",
          "orderWindow",
          "itemType",
          "tag",
          "itemNumber",
          "stateIds",
          "ruleType",
        ];
      }
      if (filterType === "user-settings") {
        chippable = ["user"];
      }
      let filters = [];
      let stateObject = { ...state };
      // Adds labels to the chips
      for (let filter in stateObject) {
        if (chippable.includes(filter)) {
          if (filter === "month") {
            stateObject[filter].forEach((f) =>
              filters.push({ type: filter, value: f })
            );
          } else if (
            filter === "brand" ||
            filter === "user" ||
            filter === "address" ||
            filter === "territory" ||
            filter === "exclusiveTerritory" ||
            filter === "tag" ||
            filter === "supplier" ||
            filter === "bu" ||
            filter === "purchaser" ||
            filter === "inMarketYear" ||
            filter === "inMarketMonthYears" ||
            filter === "budgets"
          ) {
            stateObject[filter].forEach((f) => {
              filters.push({ type: filter, value: f.name });
            });
          } else if (filter === "itemType") {
            const uniqueFilters = {}; // Object to store unique filter values
            stateObject[filter].forEach((f) => {
              const filterValue = f.name;
              if (!uniqueFilters[filterValue]) {
                filters.push({
                  type: filter,
                  value: filterValue,
                });
                uniqueFilters[filterValue] = true; // Mark the filter value as seen
              }
            });
          } else if (filter === "program") {
            stateObject[filter].forEach((f) => {
              filters.push({
                type: filter,
                value: `${f.id} ${f.name} ${
                  f.primaryBrandName ?? f["primary-brand"]?.name
                }`,
              });
            });
          } else if (filter === "programType") {
            stateObject[filter].forEach((f) => {
              filters.push({ type: filter, value: f.description });
            });
          } else if (filter === "stateIds") {
            stateObject[filter].forEach((f) => {
              filters.push({ type: filter, value: f.code });
            });
          } else if (filter === "favItems") {
            if (stateObject[filter].length > 0) {
              filters.push({ type: filter, value: "Favorite Items" });
            }
          } else if (filter === "isAccolade") {
            if (stateObject.isAccolade === "yes") {
              filters.push({ type: filter, value: "Is Accolade" });
            } else if (stateObject.isAccolade === "no") {
              filters.push({ type: filter, value: "Is Not Accolade" });
            }
          } else if (filter === "budgetId" && stateObject[filter]) {
            filters.push({
              type: filter,
              value: `Budget ID ${stateObject[filter]}`,
            });
          } else if (filter === "anaplanCode" && stateObject[filter]) {
            filters.push({
              type: filter,
              value: `Anaplan Code ${stateObject[filter]}`,
            });
          } else if (filter === "anaplanProgramType" && stateObject[filter]) {
            filters.push({
              type: filter,
              value: `Anaplan Prog. Type ${upCase(stateObject[filter], "-")}`,
            });
          } else if (filter === "noBudgets" && stateObject[filter]) {
            filters.push({ type: filter, value: "No Budgets" });
          } else if (stateObject[filter]) {
            filters.push({ type: filter, value: stateObject[filter] });
          }
        }
      }
      let filteredFilters = filters.filter(
        (f) =>
          f.value !== "all" &&
          f.value !== "all-orders" &&
          f.value !== "not-pre-order" &&
          f.value !== "not-draft" &&
          f.value !== "Anaplan Prog. Type All"
      );
      state.chipList = filteredFilters;
    },
  },
});

export const {
  setFiltersOpen,
  setFilterType,
  setDefaultFilters,
  updateMultipleFilters,
  updateSingleFilter,
  setSorted,
  setClear,
  setClearFreeType,
  setFetchCurrent,
  resetFilters,
  setChips,
} = filterSlice.actions;

export default filterSlice.reducer;
