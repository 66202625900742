/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";
import PropTypes from "prop-types";
import { countryNames } from "src/utility/constants";

import { axiosGet } from "../../api/axiosCalls";
import { useInput } from "../../hooks/InputHooks";
import {
  addNewAddress,
  checkName,
  setNameTaken,
  setUpdateSuccess,
  updateAddressById,
} from "../../redux/slices/addresses/addressSlice";
import CountrySelector from "../Utility/Selectors/CountrySelector";
import StateSelector from "../Utility/Selectors/StateSelector";
import SubstateSelector from "../Utility/Selectors/SubstateSelector";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AddressModal = ({
  open,
  handleClose,
  type,
  id,
  addressList,
  disabled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addRef = useRef(null);

  const {
    isUpdateLoading,
    isNameCheckLoading,
    isNameTaken,
    updateStatus,
    error,
  } = useSelector((state) => state.addresses);
  const { role, currentTerritory } = useSelector((state) => state.user);

  const {
    value: name,
    bind: bindName,
    setValue: setName,
    reset: resetName,
    setChange: setNameChange,
    change: nameChange,
  } = useInput("");
  const {
    value: addressOne,
    bind: bindAddressOne,
    setValue: setAddressOne,
    reset: resetAddressOne,
  } = useInput("");
  const {
    value: addressTwo,
    bind: bindAddressTwo,
    setValue: setAddressTwo,
    reset: resetAddressTwo,
  } = useInput("");
  const {
    value: attn,
    bind: bindAttn,
    setValue: setAttn,
    reset: resetAttn,
  } = useInput("");
  const {
    value: city,
    bind: bindCity,
    setValue: setCity,
    reset: resetCity,
  } = useInput("");
  const {
    value: zip,
    bind: bindZip,
    setValue: setZip,
    reset: resetZip,
  } = useInput("");
  const {
    value: phoneNumber,
    bind: bindPhoneNumber,
    setValue: setPhoneNumber,
    reset: resetPhoneNumber,
  } = useInput("");

  const [country, setCountry] = useState("USA");
  const [state, setState] = useState(null);
  const [selectedSubstateId, setSelectedSubstateId] = useState("");
  const [hasFetchedName, setHasFetchedName] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [modalError, setModalError] = useState(false);
  const [status, setStatus] = useState("active");

  const handleSetCountry = (value) => {
    setState(null);
    setCountry(value);
  };

  const clearAddress = () => {
    resetName();
    resetAddressOne();
    resetAddressTwo();
    resetAttn();
    resetCity();
    resetZip();
    resetPhoneNumber();
    setCountry("USA");
    setState(null);
    setStatus("active");
    setHasFetchedName(false);
    dispatch(setNameTaken({ value: false, address: null }));
  };

  const handleSubmit = () => {
    if (
      name.length === 0 ||
      addressOne.length === 0 ||
      city.length === 0 ||
      !state ||
      zip.length === 0
    ) {
      setModalError("Please fill out all fields");
    } else {
      setModalError(null);
      const address = {
        name: name,
        addressOne: addressOne,
        addressTwo: addressTwo,
        attn: attn,
        city: city,
        state: state.id,
        zip: zip,
        phoneNumber: phoneNumber.length > 0 ? phoneNumber : null,
        country: countryNames[country],
        territoryId: currentTerritory,
        isActive: status === "active" ? true : false,
        type: "custom",
        substate: selectedSubstateId,
      };
      if (type === "edit") {
        dispatch(updateAddressById(id, address));
      } else {
        dispatch(addNewAddress(address));
      }
    }
  };

  const handleBlur = () => {
    if (nameChange) {
      dispatch(checkName(name));
      setHasFetchedName(true);
      setNameChange(false);
    }
  };

  const handleActiveSwitch = () => {
    setStatus(status === "active" ? "inactive" : "active");
  };

  useEffect(() => {
    if (hasFetchedName && !isNameTaken && !isNameCheckLoading) {
      addRef.current.childNodes[1].firstChild.focus();
      setHasFetchedName(false);
    }
  }, [hasFetchedName, isNameTaken, isNameCheckLoading]);

  useEffect(() => {
    if (
      !isUpdateLoading &&
      (type === "edit" || updateStatus) &&
      (!currentAddress || currentAddress.id !== id)
    ) {
      let address = addressList.find((add) => add.id === id);
      if (address) {
        setCurrentAddress(address);
        setName(address.name);
        setAddressOne(address.streetAddressOne);
        setAddressTwo(address.streetAddressTwo ? address.streetAddressTwo : "");
        setAttn(address.attn);
        setCity(address.city);
        setState(address.state);
        setZip(address.zip);
        setPhoneNumber(address.phoneNumber ? address.phoneNumber : "");
        setCountry(_.invert(countryNames)[address.country] ?? address.country);
        setStatus(address.isActive === "Yes" ? "active" : "inactive");
        setSelectedSubstateId(address.substate.id);
      }
    }
  }, [
    addressList,
    isUpdateLoading,
    type,
    updateStatus,
    currentAddress,
    id,
    setCurrentAddress,
    setName,
    setAddressOne,
    setAddressTwo,
    setAttn,
    setCity,
    setStatus,
    setState,
    setZip,
    setPhoneNumber,
    setCountry,
  ]);

  useEffect(() => {
    dispatch(setUpdateSuccess({ updateStatus: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setModalError(error);
    }
  }, [error, setModalError]);

  useEffect(() => {
    const setDefaultSubState = async () => {
      const subStates = await axiosGet(
        `/api/sub-states?filter[state_id]=${state.id}`
      );
      if (subStates.error) {
        setModalError(subStates.error);
      } else if (
        !subStates.data?.some((sub) => sub.id === selectedSubstateId)
      ) {
        setSelectedSubstateId(subStates.data[0]?.id);
      }
    };
    if (state?.id) {
      setDefaultSubState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          setModalError(false);
          clearAddress();
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <div
          style={{ display: "flex", width: "100%", flexDirection: "column" }}
        >
          <DialogTitle>
            <Typography className={classes.headerText}>
              {type === "edit" ? `Editing ${name}` : "New Address"}
            </Typography>
          </DialogTitle>
          {["super", "field2"].includes(role) && (
            <FormControlLabel
              style={{ marginLeft: "20px" }}
              disabled={disabled}
              control={
                <Switch
                  checked={status === "active"}
                  onChange={handleActiveSwitch}
                  name="active-switch"
                />
              }
              label={status === "active" ? "Active" : "Inactive"}
            />
          )}
        </div>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              setModalError(false);
              clearAddress();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div tw="flex flex-col gap-4">
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              name="name"
              type="text"
              label="Name / Reference Id"
              onBlur={(evt) => handleBlur(evt)}
              error={isNameTaken}
              helperText={isNameTaken ? "Address name already in use" : null}
              disabled={isNameCheckLoading || disabled}
              {...bindName}
            />
            <TextField
              ref={addRef}
              fullWidth
              variant="outlined"
              color="secondary"
              name="address-one"
              type="text"
              label="Address Line One"
              disabled={
                isNameCheckLoading ||
                isNameTaken ||
                name.length === 0 ||
                disabled
              }
              {...bindAddressOne}
            />
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              name="address-two"
              type="text"
              label="Address Line Two"
              disabled={
                isNameCheckLoading ||
                isNameTaken ||
                name.length === 0 ||
                disabled
              }
              {...bindAddressTwo}
            />
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              name="attn"
              type="text"
              label="Attention"
              disabled={name.length === 0 || disabled}
              {...bindAttn}
            />
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              name="phone-number"
              type="text"
              label="Phone Number"
              disabled={
                isNameCheckLoading ||
                isNameTaken ||
                name.length === 0 ||
                disabled
              }
              {...bindPhoneNumber}
            />
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              name="city"
              type="text"
              label="City"
              disabled={
                isNameCheckLoading ||
                isNameTaken ||
                name.length === 0 ||
                disabled
              }
              {...bindCity}
            />
            <CountrySelector
              currentCountry={country}
              setCurrentCountry={handleSetCountry}
              role={role}
              disabled={
                isNameCheckLoading ||
                isNameTaken ||
                name.length === 0 ||
                disabled
              }
            />
            <StateSelector
              setState={setState}
              state={state}
              countryFilter={country}
              disabled={
                isNameCheckLoading ||
                isNameTaken ||
                name.length === 0 ||
                disabled
              }
            />
            {state?.hasSubStateOptions && (
              <SubstateSelector
                selectedSubstateId={selectedSubstateId}
                setSelectedSubstateId={setSelectedSubstateId}
                state={state}
                setError={setModalError}
                disabled={
                  isNameCheckLoading ||
                  isNameTaken ||
                  name.length === 0 ||
                  disabled
                }
              />
            )}
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              name="zip"
              type="text"
              label="Zip Code"
              disabled={
                isNameCheckLoading ||
                isNameTaken ||
                name.length === 0 ||
                disabled
              }
              {...bindZip}
            />
            {isNameTaken && (
              <>
                <br />
                <Typography className={classes.bodyText}>
                  Address Name Already Taken
                </Typography>
                <br />
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => clearAddress()}
                >
                  CLEAR ADDRESS
                </Button>
                <br />
              </>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                id="profile"
                onClick={handleSubmit}
                disabled={isNameTaken || disabled}
                style={{
                  float: "right",
                  marginBottom: "20px",
                  minWidth: "62px",
                }}
              >
                {isUpdateLoading ? <CircularProgress /> : "SUBMIT"}
              </Button>
              {!isUpdateLoading && modalError && (
                <Typography
                  className={classes.bodyText}
                  style={{ color: "#920000" }}
                >
                  {modalError}
                </Typography>
              )}
              {!isUpdateLoading && updateStatus && (
                <Typography className={classes.bodyText}>
                  {type === "edit"
                    ? "Update Successful!"
                    : "New Address Added Successfully!"}
                </Typography>
              )}
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddressModal.propTypes = {
  orderSetId: PropTypes.string,
  orderType: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default React.memo(AddressModal);
