import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Program } from "@models";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const programsKeyFactory = createQueryKeys("programs", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<Program[]>("programs", { params }),
  }),
});

export const useProgramsPaginated = buildPaginatedQuery(
  programsKeyFactory.paginated
);
