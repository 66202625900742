import React, { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import { CSVLink } from "@utils/csv";

import FilterChipList from "../components/Filtering/FilterChipList";
import PurchaseOrderHistoryTable from "../components/Purchasing/PurchaseOrderHistoryTable";
import { SUPPLIER_ROLES } from "../constants/permissions";
import { useInitialFilters } from "../hooks/UtilityHooks";
import {
  setSorted,
  updateMultipleFilters,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import {
  clearPOReport,
  fetchNextFilteredPOHistory,
  fetchPOReport,
  setTriggerCSVFalse,
} from "../redux/slices/purchasing/purchaseOrderHistorySlice";
import { formatMoney } from "../utility/utilityFunctions";

/*
Purhcase order history is a line by line view of purchase order variants (so each row will represent
a single item on a purchase order).  The status filters are different for Supplier users and other
users, and each 'status' will either filter directly on a purchase order status, or be a combination
of a status and a boolean. For example, ship hold means that the purchase order is in-progress, but
has a shipping paramter that is on hold due to compliance, or the new status for Supplier users means
that the purchase order has been submitted, (but its a new purchase order for the supplier, so they
would use the new filter in the UI to see it).
*/

const defaultCurrentFilters = {
  fromDate: format(new Date("02/01/2021"), "MM/dd/yyyy"),
  toDate: format(new Date(), "MM/dd/yyyy"),
  supplier: [],
  brand: [],
  program: [],
  itemType: [],
  status: "all",
  poNum: "",
  itemNumber: "",
  excludedSupplier: null,
  type: "all",
  sortOrder: "desc",
  sortOrderBy: "submittedDate",
};

const defaultSupplierNewFilters = {
  fromDate: format(new Date("02/01/2021"), "MM/dd/yyyy"),
  toDate: format(new Date(), "MM/dd/yyyy"),
  supplier: [],
  brand: [],
  program: [],
  itemType: [],
  status: "submitted",
  hasShipHold: false,
  poNum: "",
  itemNumber: "",
  type: "all",
  sortOrder: "desc",
  sortOrderBy: "submittedDate",
};

const defaultSupplierInProgressFilters = {
  fromDate: format(new Date("02/01/2021"), "MM/dd/yyyy"),
  toDate: format(new Date(), "MM/dd/yyyy"),
  supplier: [],
  brand: [],
  program: [],
  itemType: [],
  status: "in-progress",
  hasShipHold: false,
  poNum: "",
  itemNumber: "",
  type: "all",
  sortOrder: "desc",
  sortOrderBy: "submittedDate",
};

const defaultSupplierShipHoldFilters = {
  fromDate: format(new Date("02/01/2021"), "MM/dd/yyyy"),
  toDate: format(new Date(), "MM/dd/yyyy"),
  supplier: [],
  brand: [],
  program: [],
  itemType: [],
  status: "shipping-hold",
  hasShipHold: true,
  poNum: "",
  itemNumber: "",
  type: "all",
  sortOrder: "desc",
  sortOrderBy: "submittedDate",
};

const defaultHistoryFilters = {
  fromDate: format(new Date("02/01/2021"), "MM/dd/yyyy"),
  toDate: format(new Date(), "MM/dd/yyyy"),
  supplier: [],
  brand: [],
  program: [],
  itemType: [],
  status: "complete",
  poNum: "",
  itemNumber: "",
  excludedSupplier: null,
  type: "all",
  sortOrder: "desc",
  sortOrderBy: "submittedDate",
};

const filterOptionMap = {
  current: defaultCurrentFilters,
  new: defaultSupplierNewFilters,
  inProgress: defaultSupplierInProgressFilters,
  shippingHold: defaultSupplierShipHoldFilters,
  complete: defaultHistoryFilters,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PurchaseOrderHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filterOption } = useParams();
  const tableRef = useRef(null);

  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });
  const [currentView, setCurrentView] = useState(filterOption);

  const nextLink = useSelector((state) => state.purchaseOrderHistory.nextLink);
  const isNextLoading = useSelector(
    (state) => state.purchaseOrderHistory.isNextLoading
  );
  const poReport = useSelector((state) => state.purchaseOrderHistory.poReport);
  const triggerCSV = useSelector(
    (state) => state.purchaseOrderHistory.triggerCSVDownload
  );
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredPOHistory(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const currentUserRole = useSelector((state) => state.user.role);
  const isSupplier = SUPPLIER_ROLES.includes(currentUserRole);

  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentChannelFilter = useSelector((state) => state.filters.channel);
  const isPOsLoading = useSelector(
    (state) => state.purchaseOrderHistory.isLoading
  );
  const currentPOs = useSelector((state) => state.purchaseOrderHistory.pos);
  const filterObject = useSelector((state) => state.filters);

  const defaultFilters = filterOptionMap[filterOption];

  if (!isSupplier) {
    defaultFilters.channel = currentChannel;
  }

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  const handlePOReport = () => {
    dispatch(fetchPOReport(filterObject));
  };

  useEffect(() => {
    if (triggerCSV && currentCSV.data.length === 0) {
      let csvHeaders = [
        { key: "poNum", label: "PO. #" },
        { key: "poExternalSapId", label: "SAP PO. #" },
        { key: "supplierReference", label: "Supplier Ref #" },
        { key: "itemNumber", label: "Seq. #" },
        { key: "supplier", label: "Supplier" },
        { key: "brand", label: "Brand" },
        { key: "projectNum", label: "Project #" },
        { key: "itemType", label: "Item Type" },
        { key: "itemDesc", label: "Item Description" },
        { key: "quantity", label: "Quantity" },
        { key: "estCost", label: "Est. Cost/Unit" },
        { key: "actCost", label: "Act. Cost/Unit" },
        { key: "totalCost", label: "Total Cost" },
        { key: "status", label: "Status" },
        { key: "submittedDate", label: "Submitted Date" },
        { key: "inMarketDate", label: "In Market Date" },
        { key: "shipmentCount", label: "Shipment Count" },
        { key: "shipperTape", label: "Shipper Tape" },
        { key: "sentToFlowAt", label: "Sent To Flow Date" },
        { key: "poCreator", label: "Purchaser" },
        { key: "allocated", label: "Allocated" },
      ];

      csvHeaders = isSupplier
        ? csvHeaders.filter(
            (cell) =>
              cell.key !== "supplier" &&
              cell.key !== "estCost" &&
              cell.key !== "sentToFlowAt"
          )
        : currentUserRole === "purchaser"
        ? csvHeaders.filter(
            (cell) =>
              cell.key !== "sentToFlowAt" &&
              cell.key !== "shipmentCount" &&
              cell.key !== "shipperTape" &&
              cell.key !== "invoiceNumber"
          )
        : !isSupplier && currentUserRole !== "super"
        ? csvHeaders.filter(
            (cell) =>
              cell.key !== "shipmentCount" &&
              cell.key !== "shipperTape" &&
              cell.key !== "invoiceNumber"
          )
        : csvHeaders;

      if (currentUserRole === "purchaser") {
        csvHeaders = csvHeaders.filter((h) => h.key !== "sentToFlowAt");
      }

      let csvData = [];
      poReport.forEach((po) => {
        if (po.itemType !== "Set Up Fee") {
          let data = {
            poNum: po.poNum,
            poExternalSapId: po.poExternalSapId,
            supplierReference:
              po.supplierReference.length > 0 ? po.supplierReference : "---",
            invoiceNumber: po.invoiceNumber,
            itemNumber: po.displayId,
            supplier: po.supplier,
            brand: po.brand && po.brand !== "---" ? po.brand.join(", ") : "---",
            projectNum: po.projectNum,
            itemType: po.itemType,
            itemDesc: po.itemDesc,
            quantity: po.totalQty,
            estCost: formatMoney(po.estCost, true),
            actCost: formatMoney(po.actCost, true),
            totalCost: formatMoney(po.totalCost, true),
            status: po.status[0].toUpperCase() + po.status.slice(1),
            submittedDate: po.submittedDate,
            inMarketDate: po.inMarketDate,
            shipmentCount: po.shipmentCount,
            shipperTape: po.keyAcctTape,
            sentToFlowAt: po.sentToFlowAt ?? "---",
            poCreator: po.purchasedBy,
            allocated: po.allocated,
          };
          csvData.push(data);
        }
      });
      setCurrentCSV({
        data: csvData,
        headers: csvHeaders,
      });
    }
  }, [poReport, triggerCSV, currentCSV, currentUserRole, isSupplier]);

  useEffect(() => {
    if (!isSupplier) {
      if (currentChannel !== currentChannelFilter) {
        dispatch(
          updateSingleFilter({
            filter: "channel",
            value: currentChannel,
          })
        );
        dispatch(setSorted());
      }
    }
  }, [
    currentUserRole,
    currentChannel,
    currentChannelFilter,
    isSupplier,
    dispatch,
  ]);

  useInitialFilters("history-po", defaultFilters);

  useEffect(() => {
    if (currentView !== filterOption) {
      setCurrentView(filterOption);
      setCurrentView(filterOption);
      dispatch(
        updateMultipleFilters({ filterObject: filterOptionMap[filterOption] })
      );
      dispatch(setSorted());
    }
  }, [currentView, setCurrentView, filterOption, dispatch]);

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearPOReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!triggerCSV && currentCSV.data.length > 0) {
      setCurrentCSV({
        data: [],
        headers: [],
      });
    }
  }, [triggerCSV, currentCSV, setCurrentCSV]);

  return (
    <>
      <Helmet>
        <title>RTA | Purchase Order History</title>
        {isSupplier && !filtersOpen && (
          <script type="text/javascript">{` Beacon('suggest', ['601438c22042ff6d1b2a8abb'])`}</script>
        )}
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Purchase Order History
          </Typography>
          <div
            style={{
              display: "flex",
              width: "250px",
              justifyContent: "flex-end",
            }}
          >
            {!triggerCSV && currentCSV.data.length === 0 && (
              <span>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={handlePOReport}
                  disabled={currentPOs.length === 0}
                >
                  GENERATE REPORT
                </Button>
              </span>
            )}
            {triggerCSV && currentCSV.data.length > 0 && (
              <CSVLink
                data={currentCSV.data}
                headers={currentCSV.headers}
                filename="rta_purchase_order_history.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            )}
            <Tooltip title="Print POs">
              <IconButton onClick={handlePrint} size="large">
                <PrintIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <PurchaseOrderHistoryTable
          pos={currentPOs}
          posLoading={isPOsLoading}
          handleSort={handleSort}
          scrollRef={scrollRef}
          tableRef={tableRef}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <br />
    </>
  );
};

export default PurchaseOrderHistory;
