/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Edit } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, TextField } from "@mui/material";

import { isBefore } from "date-fns";

import { useFutureInMarketMonths } from "../../../hooks/planningToolHooks";
import { updateProgram } from "../../../redux/slices/planningTool/programSlice";
import { formatDate } from "../../../utility/utilityFunctions";
import { DatePicker, SelectField } from "../../Forms/DefaultInputs";
import { StyledButton } from "../../StyledComponents";
import OrderTypeSelector from "../../Utility/Selectors/OrderTypeSelector";
import formatInMarketMonthOptions from "../formatInMarketMonthOptions";

const ProgramEditModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { currentProgram: program, isUpdateLoading } = useSelector(
    (state) => state.program
  );
  const [programName, setProgramName] = useState(program.name);
  const [orderType, setOrderType] = useState(program.orderType);
  const [inMarketStartDate, setInMarketStartDate] = useState(
    formatDate(program.startDate)
  );
  const [inMarketEndDate, setInMarketEndDate] = useState(
    formatDate(program.endDate)
  );

  const [ocmId, setOcmId] = useState(program.orderCalendarMonthId);

  const futureOcms = useFutureInMarketMonths();
  const ocmOptions = formatInMarketMonthOptions([
    // Insures the current OCM is in the list of options
    ...(program.orderCalendarMonthId &&
    !futureOcms.find((f) => f.id === program.orderCalendarMonthId)
      ? [program.orderCalendarMonth]
      : []),
    ...futureOcms,
  ]);

  const canSubmit =
    programName &&
    ((orderType === "pre-order" && ocmId) ||
      (orderType !== "pre-order" &&
        isBefore(inMarketStartDate, inMarketEndDate)));

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateProgram({
        id: program.id,
        programData: {
          name: programName,
          orderType,
          startDate: inMarketStartDate,
          endDate: inMarketEndDate,
          orderCalendarMonthId: ocmId,
        },
        brands: program.brand,
        territories: program.territories,
      })
    );
    setHasSubmitted(true);
  };

  useEffect(() => {
    if (!isUpdateLoading && hasSubmitted) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateLoading, hasSubmitted]);

  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth="sm">
      <DialogContent>
        <h3 tw="mb-4">Edit National Program</h3>
        <form onSubmit={handleUpdate} tw="space-y-6">
          <TextField
            label="Program Name"
            required
            fullWidth
            variant="outlined"
            size="small"
            value={programName}
            onChange={(e) => {
              setProgramName(e.target.value);
            }}
          />
          <OrderTypeSelector
            handleOrderType={setOrderType}
            orderType={orderType}
            setType={setOrderType}
            classes={{}}
            filterType="program"
            required={true}
          />
          {orderType === "pre-order" ? (
            <SelectField
              fullWidth
              tw="w-full"
              label="In Market Month"
              value={ocmId}
              onChange={(e) => setOcmId(e.target.value)}
              options={ocmOptions}
            />
          ) : (
            <div tw="flex gap-2">
              <DatePicker
                label="In Market Start"
                value={inMarketStartDate}
                onChange={(e) => {
                  setInMarketStartDate(e);
                }}
              />
              <DatePicker
                label="In Market End"
                value={inMarketEndDate}
                onChange={(e) => {
                  setInMarketEndDate(e);
                }}
              />
            </div>
          )}
          <StyledButton
            cta
            type="submit"
            loading={isUpdateLoading}
            disabled={!canSubmit}
          >
            Save and close
          </StyledButton>
          <p tw="text-red-700 text-sm">
            {orderType === "pre-order" &&
              !ocmId &&
              "Please select an In Market Month"}
            {orderType !== "pre-order" &&
              !isBefore(inMarketStartDate, inMarketEndDate) &&
              "In Market End must be after In Market Start"}
          </p>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const ProgramEditButton = () => {
  const [modal, handleModal] = useState(false);
  return (
    <>
      <IconButton
        edge="start"
        title="Edit Program Info"
        tw="text-neutral-400 hover:text-neutral-600"
        onClick={() => handleModal(true)}
      >
        <Edit />
      </IconButton>
      {modal && (
        <ProgramEditModal open={true} handleClose={() => handleModal(false)} />
      )}
    </>
  );
};

export default ProgramEditButton;
