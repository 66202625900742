/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { setFiltersOpen } from "src/redux/slices/filterSlice";

import ImageWrapper from "../components/Utility/ImageWrapper";
import { useNoFetch } from "../hooks/UtilityHooks";

/*
Handles displaying a dashboard like UI for all reports, and conditionally renders the cards
based on the users role.
*/

const Report = ({ name, link, desc }) => (
  <Link
    className="group"
    tw="
      block rounded p-4
      border border-transparent
      transition hover:(bg-neutral-50 border-neutral-300)"
    to={link}
  >
    <h3 tw="text-lg text-neutral-800 font-medium group-hover:underline">
      {name}
    </h3>
    <p tw="text-neutral-600">{desc}</p>
  </Link>
);

const ReportSection = ({ title, icon, children }) => (
  <section tw="relative flex items-start border border-neutral-300 rounded-lg p-3">
    <div tw="w-40! sticky top-24 m-4">
      <h2 tw="text-xl font-bold text-neutral-900 mb-2 text-center">{title}</h2>
      <div tw="rounded-full border-4 border-neutral-100">
        <ImageWrapper
          imgUrl={`https://res.cloudinary.com/brandhub/image/upload/${icon}`}
          alt={title}
        />
      </div>
    </div>
    <div tw="w-full max-w-prose">
      <div>{children}</div>
    </div>
  </section>
);

const ReportsLanding = () => {
  const dispatch = useDispatch();

  const currentUserRole = useSelector((state) => state.user.role);

  const isRole = (...args) => args.includes(currentUserRole);

  const purchaserAdmin = isRole(
    "purchaser",
    "select-purchaser",
    "super",
    "read-only"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  return (
    <>
      <Helmet>
        <title>RTA | Reports</title>
      </Helmet>
      <div tw="max-w-screen-md! mx-auto px-0 py-8 space-y-6">
        <h1 tw="text-2xl font-bold text-neutral-800 mb-6">Reports</h1>

        <ReportSection
          title="General"
          icon="v1617273999/prod/Icons/SELECT_Icons_Global_Network_nmevmk.png"
        >
          <Report
            name="Roadmap Forecast"
            desc="Budgeting for Forward Deployed"
            link="/reports/roadmap-forecast"
          />
          {purchaserAdmin && (
            <Report
              name="Must Haves PDF"
              desc="Images of key items that should be in the field by month"
              link="/reports/must-haves"
            />
          )}
          <Report
            name="Order Window Summary Presentation"
            desc="This report provides a PDF export of items shipping to a territory, state, or specific address, on a specified order window. The PDF includes product images and quantities shipping to each location. Can also be exported nationally for an order window, for central's insight."
            link="/reports/order-window-summary-presentation"
          />
          <Report
            name="Order History By Order"
            desc="This provides a detailed overview of the orders placed on RTA giving the following order detail; Order number, order type/window, shipping information, estimated/actual costs, etc."
            link="/orders/history/group/byOrder"
          />
          <Report
            name="Order History By Item"
            desc="Provides a detailed overview of the items ordered on RTA giving the following order detail; Order number, Sequence Number, order type/window, shipping detail, tracking information, estimated/actual costs, quantities ordered, etc."
            link="/orders/history/group/byItem"
          />
          {/* Duplicate for field1 */}
          {isRole("field1") && (
            <Report
              name="Shelf Inventory Report"
              desc="Gives an overview of the territories an item's inventory is allocated too. This report only gives insight into territory specific inventory. It does not take into consideration general or national inventory.              "
              link="/reports/variant-allocation"
            />
          )}
        </ReportSection>
        {!isRole("field1") && (
          <ReportSection
            title="Gallo Ops"
            icon="v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png"
          >
            {(purchaserAdmin || currentUserRole === "field2") && (
              <>
                <Report
                  name="Single Item Inventory"
                  desc="Provides a quick snapshot of a single item's
                  inventory and where it's located; warehouse location, inventory
                  levels, territory allocations, and pending orders."
                  link="/reports/single-item-inventory"
                />
                <Report
                  name="Item Inventory Report"
                  desc="Provides you with a snapshot over that the
                  past year for ordered inventory items. Select a warehouse to see on
                  hand inventory and order totals on items for the past 90 days, and
                  past year."
                  link="/reports/inventory"
                />
              </>
            )}
            {purchaserAdmin && (
              <>
                {!isRole("compliance") && (
                  <Report
                    name="Purchase Order Report"
                    desc="Shows high level detail regarding the costs associated with a purchase order; Extended Costs, Freight Costs, Additional Freight Costs and invoice numbers."
                    link="/reports/purchase-order-report"
                  />
                )}
                <Report
                  name="Open Purchase Order Report"
                  desc={`Exports a report of all the open purchase orders currently on RTA based on ship date. Open purchase orders are those that carry a "In Progress" or "Shipping Hold" status and do not have an invoice number in RTA.`}
                  link="/reports/open-purchase-orders"
                />
                <Report
                  name="Request for Price Summary Report"
                  desc="Provides you with a breakdown of submitted quotes from Suppliers for a Preorder or Ad Hoc Ordering Window."
                  link="/reports/rfp-summary"
                />
                {currentUserRole === "super" && (
                  <Report
                    name="Ordered Items Report"
                    desc="Provides a snapshot of the items ordered on RTA. You can filter the report by Order status and/or Order Type which will provide the total quantities ordered and canceled for an item."
                    link="/reports/ordered-items"
                  />
                )}
                <Report
                  name="PO Delivery Date Report"
                  desc="Highlights how delivery dates on POs have changed."
                  link="/reports/po-delivery-date-report"
                />
              </>
            )}
            {/* All except field1 */}
            <Report
              name="Order Window Summary Report"
              desc="Provides a summary of the total aggregated quantities for each item ordered on a Pre Order / Ad Hoc Window."
              link="/reports/order-window-summary"
            />
            <Report
              name="Order Window Detail Report"
              desc="Gives detailed order information for all items included in a Pre Order / Ad Hoc Order Window, including who ordered it and where the item is shipping too."
              link="/reports/order-window-detail"
            />
            <Report
              name="Order Window Items Report"
              desc="Exports a high-level overview of the items offered in a Pre Order / Ad Hoc Order window, including item types/descriptions and program detail."
              link="/reports/order-window-items"
            />
            {(purchaserAdmin || currentUserRole === "field2") && (
              <Report
                name="Shelf Inventory Report"
                desc="Gives an overview of the territories an item's inventory is allocated too. This report only gives insight into territory specific inventory. It does not take into consideration general or national inventory.              "
                link="/reports/variant-allocation"
              />
            )}
            {purchaserAdmin && (
              <>
                <Report
                  name="PO Compliance Report"
                  desc="Gives a detailed overview of the items currently pending compliance, in violation, or denied. It provides information such as order detail (PO #, Item #, Ship to state), compliance status, and compliance email status.              "
                  link="/reports/po-compliance"
                />
              </>
            )}
            {(purchaserAdmin || isRole("compliance")) && (
              <>
                <Report
                  name="Compliance Audit Report"
                  desc="Shows a history of actions related to compliance such as Price Overrides, Internal Approvals, Item Overrides etc.              "
                  link="/reports/compliance-audit"
                />
              </>
            )}
            <Report
              name="Custom Coupons"
              desc="Provides a snapshot of the custom coupons ordered on RTA. The report provides you with detail regarding who ordered the coupon, where's it's shipping, quantities ordered, and item details (type, promotion, etc.)"
              link="/reports/custom-coupon"
            />
            {purchaserAdmin && (
              <>
                <Report
                  name="Pre Order MOQ Analysis"
                  desc="This analysis provides a breakdown of ordered units by territory for any sequence #s that have not met MOQ on a specified Order Window (both channels)."
                  link="/reports/pre-order-moq-analysis"
                />
                <Report
                  name="Order Window MOQ"
                  desc="This analysis provides a breakdown of ordered units by territory for all sequence #s on a specified Order Window including pictures, MOQ, and Inventory on hand when applicable."
                  link="/reports/order-window-moq"
                />
                <Report
                  name="Order Window Spend"
                  desc="This analysis provides a breakdown of spending by program for all sequence #s on a specified Order Window including pictures, MOQ, and Inventory on hand when applicable.  Total spend is inclusive of shipping and tax."
                  link="/reports/order-window-spend"
                />
                <Report
                  name="Brand Recap"
                  desc="This analysis provides a breakdown of a brand’s items on a specific order window. The report lists the new and rerun items that were selected for a given order window, diving into the production costs, quantity ordered, and overall item execution to evaluate how that brand performed from an execution standpoint."
                  link="/reports/brand-recap"
                />
              </>
            )}
          </ReportSection>
        )}
        {isRole("super", "select-purchaser") && (
          <ReportSection
            title="Vendor"
            icon="v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png"
          >
            <Report
              name="CDC Reports"
              desc="A series of reports that are used by the CDC or Rapid Displays for monthly focus programs, specifically for print items. These reports include the transfer advice report, CDC ship list, and CDC in-bound list."
              link="/reports/cdc"
            />
            {isRole("super", "select-purchaser", "purchaser") && (
              <Report
                name="Bulk PO Shipping Report"
                desc="This allows the user to export and upload ship lists for multiple POs at the same time."
                link="/reports/po-ship-list"
              />
            )}
            <Report
              name="Select Invoicing Report"
              desc=""
              link="/reports/invoicing"
            />
          </ReportSection>
        )}

        {purchaserAdmin && (
          <ReportSection
            title="Finance"
            icon="v1617273999/prod/Icons/SELECT_Icons_Precise_Spend_Tracking_baw9z1.png"
          >
            <Report
              name="Order Detail Report"
              desc="Provides you with estimated and actual order costs which can be filtered by actual or estimated ship dates."
              link="/reports/order-detail"
            />
            <Report
              name="Finance Journal Report"
              desc="This gives a snapshot of coded orders and proper GL accounts which can be filter by ship date."
              link="/reports/finance-journal"
            />
            {isRole("super") && (
              <Report
                name="Supplier Spend Reports"
                desc="This provides a snapshot of the total spend on RTA broken down by the Supplier. This can either be run has a high-level summary report or a more in-depth detail report which shows detail down to the PO level."
                link="/reports/supplier-spend"
              />
            )}
            <Report
              name="QN Invoice Report"
              desc="This report supports the QN budget reconciliation process."
              link="/reports/qn-invoice"
            />
            <Report
              name="PO Accrual Reporting"
              desc="This report exports all POs that should be accrued for a specific year – can be pulled as a summary or detailed report"
              link="/reports/po-accrual"
            />
          </ReportSection>
        )}
        {isRole("super", "select-purchaser") && (
          <ReportSection
            title="Select Only"
            icon="v1617273999/prod/Icons/SELECT_Icons_Order_Management_m5zx9c.png"
          >
            <Report
              name="Check Invoice Report"
              desc={`Provides a detailed breakdown of an invoice and what POs are included on that invoice. This is also known as the "Invoice Double Check" Report.`}
              link="/reports/invoice-double-check"
            />
            {isRole("super") && (
              <Report
                name="Product List Report"
                desc="Provides a list of all items in RTA, including order status, brand, est. cost, and more  "
                link="/reports/product-list"
              />
            )}
            {isRole("super") && (
              <Report
                name="Product List PDF Report"
                desc="Provides a list of all items in RTA, including product images rendered as a PDF  "
                link="/reports/product-list-pdf"
              />
            )}
          </ReportSection>
        )}
      </div>
    </>
  );
};

ReportsLanding.propTypes = {};

export default ReportsLanding;
